<template>
    <v-row class="noSpace">
        <!--Carrusel de entradas de blog-->
        <v-carousel v-if="blogList.length > 0 && imageList.length > 0" hide-delimiters class="myCarousel" height="120%">
            <!--Botón de flecha izquierda-->
            <template v-slot:prev="{ on, attrs }">
                <v-btn
                    color="#F4F6FD"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    class="ideenButton--text"
                >
                    <v-img width="12px" style="opacity: .75; margin-right: 5px;"
                           :src="require(`../../assets/blog/ideen_Arrow_grey_back.png`)"></v-img>
                    {{ blogArrows[0]['arrowBack'] }}
                </v-btn>
            </template>

            <!--Botón de flecha derecha-->
            <template v-slot:next="{ on, attrs }">
                <v-btn
                    color="#F4F6FD"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    class="ideenButton--text"
                >
                    {{ blogArrows[0]['arrowNext'] }}
                    <v-img width="12px" style="opacity: .75;  margin-left: 5px;" :src="require(`../../assets/blog/ideen_Arrow_grey.png`)"></v-img>
                </v-btn>
            </template>

            <!--Template con ciclo para generar las tarjetas de cada entrada-->
            <template v-for="(item, index) in blogList">
                <!--Verifica las columnas para ser responsivo-->
                <v-carousel-item v-if="(index + 1) % columnsNumber === 1 || columnsNumber === 1" :key="index">

                    <v-row class="flex-nowrap" style="height:88%" align="center" align-content="center">
                        <template v-for="(_, i) in columnsNumber">

                            <!--Template que contiene las tarjetas-->
                            <template v-if="(index + i) < blogList.length">
                                <v-col align="center" align-content="center" cols="12" md="4" :key="'column-' + i">
                                    <!--Deja un espacio para bajar la tarjeta central en caso de haber 3 por cada slide-->
                                    <h1
                                        v-if="indexCheck(index+i) === true && blogList.length > index+i+1 && columnsNumber !== 2 && columnsNumber !== 1"
                                        style="color: #ffffff;"
                                    >&nbsp;</h1>

                                    <h1
                                        v-if="indexCheck(index+i) === true && blogList.length > index+i+1 && columnsNumber !== 2 && columnsNumber !== 1"
                                        style="color: #ffffff;"
                                    >&nbsp;</h1>

                                    <BlogEntry_Card
                                        v-if="(+index + i) < blogList.length"
                                        :title="blogList[index+i]['title']"
                                        :category="blogList[index+i]['category']"
                                        :date="blogList[index+i]['publishDate'].toDate().toDateString()"
                                        :img="imageList[0][index+i]"
                                        :select="(index+i).toString()"
                                        color="#F4F6FD"
                                        :id="blogList[index+i].id"
                                    ></BlogEntry_Card>
                                </v-col>
                            </template>

                        </template>
                    </v-row>
                </v-carousel-item>
            </template>

        </v-carousel>
    </v-row>
</template>

<script>
import BlogEntry_Card from "@/components/cards/BlogEntry_Card";
import * as db_local from "@/constants/blog_database";

export default {
    name: "Blog_Carousel",
    components: {BlogEntry_Card},
    props: {
        blogList: Array,
        imageList: Array,
    },
    data() {
        return {
            blogArrows: null,
            //Lista de índices para que la pág. sepa qué índice (de la DB) corresponde a la tarjeta central y poder bajarla
            //Actualmente solo hay hasta el índice 49 (50 entradas de blog), y aumenta de 3 en 3
            indexesList: [
                1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49,
            ],
        }
    },
    beforeMount() {
        //Base de datos de blog
        if (this.language === 'es') {
            this.blogArrows = db_local.blog_list_es;
        } else {
            this.blogArrows = db_local.blog_list_en;
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        },

        //Determina el no. de columnas necesarias para la visualización
        columnsNumber() {
            if (this.$vuetify.breakpoint.xl) {
                return 3;
            }

            if (this.$vuetify.breakpoint.lg) {
                return 3;
            }

            if (this.$vuetify.breakpoint.md) {
                return 2;
            }
            return 1;
        }
    },
    methods: {
        //Revisa el índice para bajar la tarjeta central
        indexCheck: function (index) {
            return this.indexesList.includes(index);
        },
    }
}
</script>

<style scoped>
</style>

<style>
.myCarousel .v-window__prev, .myCarousel .v-window__next {
    top: 95%;
    position: absolute;
    margin-left: 3%;
    margin-right: 3%;
}

@media (width: 1440px) {
    .myCarousel .v-window__prev, .myCarousel .v-window__next {
        top: 95%;
        position: absolute;
        margin-left: -0.25rem;
        margin-right: -0.25%;
    }
}
</style>