<template>
    <!-- CIRCULAR PROGRESS INDICATOR -->
    <v-container v-if="blogList.length === 0 || imgLinkList.length > 1" style="height: 100vh;">
        <v-col style="height: 100vh;" align-self="center" align="center" justify="center">
            <v-row style="height: 100vh;" justify="center" align="center">
                <v-progress-circular
                    indeterminate
                    color="ideenBlue"
                    :size="90"
                    :width="9"
                ></v-progress-circular>
            </v-row>
        </v-col>
    </v-container>

    <!-- BLOGS LIST SCREEN -->
    <v-container v-else fluid class="px-0">
        <!-- SCREEN TITLE -->
        <v-row class="noSpace mt-2 mt-lg-8 mb-0 mb-lg-8">
            <v-col cols="12" class="px-lg-16 pa-0">
                <p
                    id="screenTitle"
                    class="customTitle"
                >BLOG</p>
            </v-col>
        </v-row>

        <!-- LAST BLOG ENTRY -->
        <v-row class="noSpace mb-10 mr-lg-7 justify-center justify-lg-start">
            <!-- LATEST ENTRY COVER -->
            <v-col cols="12" lg="4" class="pa-0 pl-lg-16">
                <v-img
                    class="ml-0 ml-lg-8"
                    :aspect-ratio="1"
                    :src="(imgLinkList.length >= 1 ? imgLinkList[0][0] : '')"
                    height="350"
                    @click="goToBlogEntryScreen(blogList[0]['id'])"
                ></v-img>
            </v-col>

            <!-- ENTRY DATA COLUMN -->
            <v-col cols="12" lg="8" class="px-4 pr-lg-16">
                <!-- ENTRY TITLE -->
                <p
                    @click="goToBlogEntryScreen(blogList[0]['id'])"
                    class="text-start text-lg-end pl-lg-16 pt-2 pt-lg-0 pb-0 pb-lg-15 mb-8"
                    style="font-family: Montserrat, sans-serif; font-weight: 500; font-size: 1.25em"
                >{{ blogList[0]['title'] }}</p>

                <!-- ENTRY DESCRIPTION -->
                <p
                    class="customText text-start text-lg-end pa-0 pb-2 pl-0 pl-lg-16 ml-0 ml-lg-16"
                >{{ blogList[0]['description'] }}</p>

                <!-- ENTRY DATE -->
                <p
                    class="customText text-start text-lg-end"
                    style="font-family: Montserrat, sans-serif; font-weight: 500; color: #3c3c3c;"
                >{{ blogList[0]['publishDate'].toDate().toDateString() }}</p>

                <!-- ENTRY CTA BUTTON -->
                <v-row align="end" class="pt-5 mb-8" justify="end">
                    <v-btn depressed style="background: transparent;">
                        <v-img
                            contain
                            src="../../assets/blog/ideen_Arrow.png"
                            style="opacity: .75;"
                            width="12px"
                            @click="goToBlogEntryScreen(blogList[0]['id'])"
                        ></v-img>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

        <!-- BLOG ENTRIES LIST -->
        <v-row v-if="blogList.length > 0" class="noSpace blogSection" style="color: #3c3c3c">
            <v-col class="px-3 px-lg-16">
                <v-row justify="start" align="start" class="px-6 pl-lg-3 pt-12 pt-lg-8 pb-4 pb-lg-0">
                    <p id="blogTitle" class="customTitle text-uppercase">{{ blogListTitle }}</p>
                </v-row>

                <!-- Componente de carrusel-->
                <Blog_Carousel
                    class="mb-16 pb-8"
                    :blogList="blogList"
                    :imageList="imgLinkList"
                ></Blog_Carousel>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import Blog_Carousel from "@/components/blog/Blog_Carousel";
import firebase from "firebase"
import {db} from "@/plugins/firebaseConfig";
import {blog_list_title} from "@/constants/blog_database";

export default {
    name: "BlogScreen",
    components: {Blog_Carousel},
    data() {
        return {
            blogListTitle: '',
            //Array that contains each blog entry (as an object generated from firebase document)
            blogList: [],
            //Array that contains each image document (as an object generated from  firestore/fl_files)
            imgList: [],
            //2D Array containing the image reference array contained in each firebase blog document
            imgRefList: [],
            //2D Array containing a single entry [0], which contains the firebase image url
            imgLinkList: [],
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    async mounted() {
        this.getScreenLabels();

        // Getting all blog entries
        await firebase.firestore().collection('fl_content').where('_fl_meta_.status', '==', 'publish').orderBy('publishDate', 'desc').get().then((snapshot) => {
            let entriesList = snapshot.docs;
            entriesList.forEach((entry) => {
                this.blogList.push(entry.data())
            });
        });

        //Get image into array
        await this.getImageDocReference()
    },
    methods: {
        getScreenLabels() {
            if (this.language === 'es') {
                this.blogListTitle = blog_list_title[0];
            } else {
                this.blogListTitle = blog_list_title[1];
            }
        },

        //Send generated id from "BlogRelatedEntry_List" to "Blog_Entry" in order to display selected content
        goToBlogEntryScreen(entryId) {
            const routeObject = {path: "blog/" + entryId, params: {id: entryId}};

            this.$router.push(routeObject);
        },

        //Method that retrieves de firebase image
        async getImageDocReference() {
            //Temp array used to copy the img srcs into imgLinkList
            let imageTempList = [];
            //Push each image reference into imgRefList
            for (let i = 0; i < this.blogList.length; i++) {
                this.imgRefList.push(this.blogList[i]['coverImg'])
            }
            //Get the image referenced document form firebase
            for (let i = 0; i < this.blogList.length; i++) {
                /*2D array format to get the document path: this.imgRefList[i][0].path
                /2D array format to get the document ID:this.imgRefList[i][0]._delegate._key.path.segments[6] */
                let docRef = db.collection("fl_files").doc(this.imgRefList[i][0]._delegate._key.path.segments[6])
                await docRef.get().then((doc) => {
                    if (doc.exists) {
                        //Push the document data as an object into imgList
                        this.imgList.push(doc.data())
                    }
                })
            }
            //Get the image URL using each imgList object 'file' attribute
            for (let i = 0; i < this.blogList.length; i++) {
                let storage = firebase.storage()
                let storageRef = storage.ref()
                let imgRef = storageRef.child('flamelink/media' + '/' + this.imgList[i]['file'])
                await imgRef.getDownloadURL().then(function (url) {
                    //Push the url into the temp array
                    imageTempList.push(url)
                })
            }
            //Copy the temp array into imgLinkList
            this.imgLinkList.push(imageTempList)
        }
    }
}
</script>

<style scoped>
.blogSection {
    background-color: #F4F6FD;
    padding: 0 !important;
    margin: 0 !important;
}

#screenTitle {
    color: #0131B7;
}

#blogTitle {
    color: #545454;
}

@media (max-width: 768px) {
    #screenTitle {
        text-align: center;
    }
}
</style>
